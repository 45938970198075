import React, { useState, useRef, useContext, useEffect } from "react";
import TopBar from "../../components/topbar";
import Header from "../../components/header";
import { ModalDesconto, ModalItemProposta, ModalListaClientes, ModalCliente } from "../../components/modals";
import { Row, Col, Button, Container } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import { GlobalContext } from "../../contexts/global";
import { ErroField, InputFieldPadrao, InputClienteProposta, InputFieldTextArea, SelectFieldPadrao } from "../../components/fields";
import { MdAdd, MdDelete } from "react-icons/md";
import { TbDiscount } from "react-icons/tb";
import { Hint } from "../../components/hint";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ModalRejeitarProposta, ModalNegociarProposta, ModalAprovarProposta } from "../../components/modals";
import { AlertaMensagem } from "../../components/alerts";

function TelaPropostaCliente() {

    const { conexao, loading, setLoading, alerta, setAlerta } = useContext(GlobalContext)

    const Navigate = useNavigate()

    const { idproposta } = useParams()

    const [exibeProposta, setExibeProposta] = useState(false)

    const [tituloItens, setTituloItens] = useState("ITENS DA PROPOSTA")

    const [loadingAprovar, setLoadingAprovar] = useState(false)
    const [loadingReprovar, setLoadingReprovar] = useState(false)
    const [loadingNegociar, setLoadingNegociar] = useState(false)

    const [modalRejeitar, setModalRejeitar] = useState(false)
    const [modalNegociar, setModalNegociar] = useState(false)
    const [modalAprovar, setModalAprovar] = useState(false)

    const [tipoProp, setTipoProp] = useState('padrao')

    const [local, setLocal] = useState('padrao')

    const [corDestaque, setCorDestaque] = useState('#026eb6')
    const [corHeader, setCorHeader] = useState('#f5f5f5')
    const [corBg, setCorBg] = useState('gainsboro')
    const [corFolha, setCorFolha] = useState('white')

    const [itens, setItens] = useState([]);


    const [valuesProposta, setValuesProposta] = useState({})
    const [valuesItem, setValuesItem] = useState({
        descricao: "",
        quantidade: "",
        unitario: "",
    })
    const [showModalItem, setShowModalItem] = useState(false);
    const [showModalDesconto, setShowModalDesconto] = useState(false);
    const [showModalClientes, setShowModalClientes] = useState(false);
    const [modalCliente, setModalCliente] = useState(false);

    const [desconto, setDesconto] = useState(0)

    const somaQuantidades = itens.reduce((acumulador, objeto) => {
        return acumulador + objeto.quantidade;
    }, 0);

    const somaTotais = itens.reduce((acumulador, objeto) => {
        return acumulador + objeto.total;
    }, 0);

    const propRef = useRef()

    const formataData = () => {
        const data = new Date(valuesProposta.prop_created)
        console.log(data)
        const dataFormatada = data.toLocaleDateString("pt-BR", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
        return dataFormatada
    }



    async function getProposta() {
        axios.post(conexao.endereco + '/proposals/get',
            {
                "proposal": base64_decode(idproposta)
            }, { headers: { token: localStorage.getItem('Token') } })

            .then(function (response) {
                setLoading(false)
                if (response.status == 200) {

                    if (response.data.validade == 'ATIVA' || response.data.prop_status == 'aprovada') {

                        const status = response.data.prop_status

                        if (status == 'reprovada') {

                            setExibeProposta(false)
                            setAlerta({
                                show: true,
                                titulo: 'PROPOSTA REPROVADA',
                                mensagem: 'Solicite o reenvio da proposta para aprovar ou negociar!',
                                textoBotao: 'OK'
                            })

                            return

                        }


                        setValuesProposta(response.data)
                        setItens(JSON.parse(response.data.prop_items))
                        setTipoProp(response.data.prop_type)
                        setDesconto(Number(response.data.prop_discount))
                        const cores = JSON.parse(response.data.user_colors)
                        // alert(cores.corHeader)
                        cores.corHeader.hex ? setCorHeader(cores.corHeader.hex) : setCorHeader('#f5f5f5')
                        cores.corBg.hex ? setCorBg(cores.corBg.hex) : setCorBg('gainsboro')
                        cores.corFolha.hex ? setCorFolha(cores.corFolha.hex) : setCorFolha('white')
                        cores.corDestaque.hex ? setCorDestaque(cores.corDestaque.hex) : setCorDestaque('#026eb6')



                        if (response.data.prop_type == 'individual') {
                            setTituloItens('OPÇÕES DE PROPOSTA')
                        }

                        setExibeProposta(true)

                    } else {
                        setExibeProposta(false)
                        setAlerta({
                            show: true,
                            titulo: 'Atenção',
                            mensagem: 'Proposta expirada',
                            textoBotao: 'OK'
                        })
                    }


                }
            })
            .catch(function (error) {
                console.error(error)
                setLoading(false)
            })
    }

    async function aprovarProposta() {
        setModalAprovar(true)

    }

    async function reprovarProposta() {

        // setLoadingReprovar(true)
        setModalRejeitar(true)
    }

    async function negociarProposta() {

        // setLoadingNegociar(true)
        setModalNegociar(true)

        // updateStatusProposta(valuesProposta.prop_id, 'rascunho')

    }

    async function updateStatusProposta(proposal, status, message, data) {

        axios.post(conexao.endereco + '/proposals/edit/status',
            {
                "proposal": proposal,
                "status": status,
                "message": message,
                "data": data
            }, { headers: { token: localStorage.getItem('Token') } })

            .then(function (response) {
                setLoadingAprovar(false)
                setLoadingReprovar(false)
                setLoadingNegociar(false)
                if (response.status == 200) {
                    if (status == 'aprovada') {
                        toast.success('Proposta Aprovada!')
                    }
                    if (status == 'reprovada') {
                        toast.error('Proposta Reprovada =/')
                    }
                    if (status == 'negociação') {
                        toast.info('Proposta em Negociação!')
                    }

                    getProposta()
                }
            })
            .catch(function (error) {
                console.error(error)
                setLoadingAprovar(false)
                setLoadingReprovar(false)
                setLoadingNegociar(false)
            })
    }

    useEffect(() => {

        navigator.geolocation.getCurrentPosition(function (position) {
            setLocal(position.coords.latitude + ',' + position.coords.longitude)
        })

        if (idproposta) {
            getProposta()
        }
    }, [])

    return (
        <div style={{ backgroundColor: corBg }}>

            {exibeProposta &&
                <>
                    <Container style={{ backgroundColor: corFolha, height: 50 }} className="proposta-barra-superior-branca"></Container>

                    <Row style={{ backgroundColor: corHeader }} className="proposta-header">
                        <Col lg={6} sm={12} md={12} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', paddingLeft: '10%', paddingTop: '2%', flexDirection: 'column' }}>
                            <h1 style={{ color: corDestaque, fontWeight: 'bold' }}>PROPOSTA COMERCIAL</h1>
                            <h2 style={{ color: corDestaque, fontSize: 22, fontWeight: 'bold' }}>{valuesProposta.user_business_name}</h2>
                            <br />
                            <span>{formataData()}</span>
                        </Col>
                        <Col lg={6} sm={12} md={12} className="proposta-coluna-logo">
                            <img src={valuesProposta.user_url_logo} className="proposta-logo" />
                        </Col>

                    </Row>

                    <Container style={{ backgroundColor: corFolha, zIndex: -10, paddingBottom: 80 }}>
                        {/* <span>{JSON.stringify(valuesProposta)}</span> */}
                        <Row style={{ paddingTop: 60, paddingLeft: 30, display: 'flex', flexDirection: 'column' }}>
                            <Col>
                                <h3 style={{ color: corDestaque }}>TÍTULO DA PROPOSTA</h3>
                            </Col>
                            <Col>
                                <p style={{ whiteSpace: 'pre-line', wordWrap: "break-word" }}>{valuesProposta.prop_title}</p>
                            </Col>
                        </Row>
                        <Row style={{ paddingTop: 40, paddingLeft: 30, display: 'flex', flexDirection: 'column' }}>
                            <Col>
                                <h3 style={{ color: corDestaque }}>APRESENTAÇÃO</h3>
                            </Col>
                            <Col>
                                <p style={{ whiteSpace: 'pre-line', wordWrap: "break-word" }}>{valuesProposta.prop_description}</p>
                            </Col>
                        </Row>
                        <Row style={{ paddingTop: 40, paddingLeft: 30, paddingRight: 30, display: 'flex', flexDirection: 'column' }}>
                            <Col>
                                <h3 style={{ color: corDestaque }}>{tituloItens}</h3>
                            </Col>
                            <Col style={{ marginTop: 20 }}>
                                <div className="proposta-header-itens">
                                    <Col lg={6} sm={5}>
                                        Descrição do item
                                    </Col>
                                    <Col lg={2} sm={2}>
                                        Quantidade
                                    </Col>
                                    <Col lg={2} sm={3}>
                                        Valor Unitário
                                    </Col>
                                    <Col lg={2} sm={2}>
                                        Valor Total
                                    </Col>
                                </div>
                                {itens.map((item, index) => (

                                    <div className="proposta-itens">
                                        <Col lg={6} sm={5}>
                                            {item.descricao}
                                        </Col>
                                        <Col lg={2} sm={2}>
                                            {item.quantidade}
                                        </Col>
                                        <Col lg={2} sm={3}>
                                            {item.unitario ? item.unitario.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : '0,00'}
                                        </Col>
                                        <Col lg={2} sm={2}>
                                            {item.total ? item.total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : '0,00'}
                                        </Col>

                                    </div>

                                ))}
                                <div className="proposta-footer-itens">
                                    <Col lg={6} sm={5} >

                                    </Col>
                                    <Col lg={2} sm={2}>
                                        {tipoProp === 'padrao' && <strong>{somaQuantidades}</strong>}
                                    </Col>
                                    <Col lg={2} sm={3}>

                                    </Col>
                                    <Col lg={2} sm={2}>
                                        {tipoProp === 'padrao' && <strong>{somaTotais.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</strong>}
                                    </Col>
                                </div>
                            </Col>
                        </Row>
                        {valuesProposta.prop_attachment &&
                            <Row style={{ paddingTop: 40, paddingLeft: 30, paddingRight: 30, display: 'flex', flexDirection: 'column' }}>
                                <Col>
                                    <h3 style={{ color: corDestaque }}>ANEXO DA PROPOSTA</h3>
                                </Col>
                                <Col>
                                    <a href={`${valuesProposta.prop_attachment}`} target="_blank" referrerPolicy="no-referrer"> <p style={{ whiteSpace: 'pre-line', wordWrap: "break-word" }}>Visualizar Anexo</p></a>
                                </Col>
                            </Row>
                        }
                        {valuesProposta.prop_type == 'padrao' &&
                            <Row style={{ paddingTop: 40, paddingLeft: 30, paddingRight: 30, display: 'flex', flexDirection: 'column' }}>
                                <Col>
                                    <h3 style={{ color: corDestaque }}>TOTAIS DA PROPOSTA</h3>
                                </Col>
                                <Col>
                                    <span>Total dos Itens</span><p><strong>{Number(valuesProposta.prop_total).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</strong></p>
                                    <span>Desconto Concedido</span><p><strong>{Number(valuesProposta.prop_discount).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</strong></p>
                                    <span>Valor Final da Proposta</span><p><strong>{Number(valuesProposta.prop_final_value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</strong></p>
                                </Col>
                            </Row>
                        }
                        {valuesProposta.prop_payment_conditions &&
                            <Row style={{ paddingTop: 40, paddingLeft: 30, paddingRight: 30, display: 'flex', flexDirection: 'column' }}>
                                <Col>
                                    <h3 style={{ color: corDestaque }}>CONDIÇÕES DE PAGAMENTO</h3>
                                </Col>
                                <Col>
                                    <p style={{ whiteSpace: 'pre-line', wordWrap: "break-word" }}>{valuesProposta.prop_payment_conditions}</p>
                                </Col>
                            </Row>
                        }
                        {valuesProposta.prop_validity !== 0 && valuesProposta.prop_validity &&
                            <Row style={{ paddingTop: 40, paddingLeft: 30, paddingRight: 30, display: 'flex', flexDirection: 'column' }}>
                                <Col>
                                    <h3 style={{ color: corDestaque }}>VALIDADE DA PROPOSTA</h3>
                                </Col>
                                <Col>
                                    <p>{valuesProposta.prop_validity} Dias</p>
                                </Col>
                            </Row>
                        }
                        <Row style={{ paddingTop: 40, paddingLeft: 30, paddingRight: 30, display: 'flex', flexDirection: 'column' }}>
                            <Col>
                                <h3 style={{ color: corDestaque }}>APROVAÇÃO</h3>
                            </Col>
                        </Row>
                        {valuesProposta.prop_status !== 'aprovada' &&
                            <Row style={{ paddingTop: 40, paddingLeft: 30, paddingRight: 30, display: 'flex', justifyContent: 'center' }}>
                                <Col sm={12} lg={4} style={{ backgroundColor: corHeader }} className="botao-proposta-cliente" onClick={aprovarProposta}>
                                    <h3 style={{ color: corDestaque }}>{loadingAprovar ? 'AGUARDE' : 'APROVAR'}</h3>
                                </Col>
                                <Col sm={12} lg={4} style={{ backgroundColor: corHeader }} className="botao-proposta-cliente" onClick={reprovarProposta}>
                                    <h3 style={{ color: corDestaque }}>{loadingReprovar ? 'AGUARDE' : 'REJEITAR'}</h3>
                                </Col>
                                {valuesProposta.prop_negociation &&
                                    <Col sm={12} lg={4} style={{ backgroundColor: corHeader }} className="botao-proposta-cliente" onClick={negociarProposta}>
                                        <h3 style={{ color: corDestaque }}>{loadingNegociar ? 'AGUARDE' : 'NEGOCIAR'}</h3>
                                    </Col>
                                }
                            </Row>
                        }
                        {valuesProposta.prop_status == 'aprovada' &&
                            <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 40 }}>
                                <strong style={{ color: corDestaque }}>PROPOSTA JÁ FOI APROVADA</strong>
                            </Col>
                        }


                    </Container>
                    <Container style={{ backgroundColor: corFolha, height: 50, display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                        <a style={{ color: corDestaque }} href="https://propostacomercial.com.br" target="_blank" referrerPolicy="no-referrer"> <span>www.propostacomercial.com.br</span></a>
                    </Container>
                </>
            }
            <ToastContainer />

            <ModalRejeitarProposta
                show={modalRejeitar}
                corBotao={corDestaque}
                setShow={setModalRejeitar}
                onHide={() => setModalRejeitar(false)}
                click={(values) => {

                    let userAgent = navigator.userAgent;
                    let Platform = navigator.platform;
                    let Language = navigator.language;

                    axios.get('https://api.ipify.org?format=json', {}, {})
                        .then(function (response) {
                            const dataUser = {
                                "ip": response.data.ip,
                                "userAgent": userAgent,
                                "platform": Platform,
                                "language": Language,
                                "local": local,
                                "valor_proposta": valuesProposta.prop_final_value,
                            }

                            updateStatusProposta(valuesProposta.prop_id, 'reprovada', values.observacoes, dataUser)
                        })

                }}
            />

            <ModalNegociarProposta
                show={modalNegociar}
                corBotao={corDestaque}
                setShow={setModalNegociar}
                onHide={() => setModalNegociar(false)}
                click={(values) => {

                    let userAgent = navigator.userAgent;
                    let Platform = navigator.platform;
                    let Language = navigator.language;

                    axios.get('https://api.ipify.org?format=json', {}, {})
                        .then(function (response) {
                            const dataUser = {
                                "ip": response.data.ip,
                                "userAgent": userAgent,
                                "platform": Platform,
                                "language": Language,
                                "local": local,
                                "valor_proposta": valuesProposta.prop_final_value,
                            }

                            updateStatusProposta(valuesProposta.prop_id, 'negociação', values.observacoes, dataUser)
                        })

                }}
            />

            <ModalAprovarProposta
                show={modalAprovar}
                corBotao={corDestaque}
                setShow={setModalAprovar}
                dialogClassName="modal-50"
                onHide={() => setModalAprovar(false)}
                tipoProp={valuesProposta.prop_type}
                click={(values) => {

                    let userAgent = navigator.userAgent;
                    let Platform = navigator.platform;
                    let Language = navigator.language;

                    axios.get('https://api.ipify.org?format=json', {}, {})
                        .then(function (response) {
                            const dataUser = {
                                "ip": response.data.ip,
                                "userAgent": userAgent,
                                "platform": Platform,
                                "language": Language,
                                "local": local,
                                "valor_proposta": valuesProposta.prop_final_value,
                                "item_aprovado": values.item_aprovado
                            }

                            updateStatusProposta(valuesProposta.prop_id, 'aprovada', values.observacoes, dataUser)
                        })


                }}
            >
                <option value={null}>Selecione</option>
                {itens.map((item, index) => (
                    <option value={item.descricao}>{item.descricao} por {item.total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</option>
                ))}


            </ModalAprovarProposta>

            <AlertaMensagem
                show={alerta.show}
                close={() => setAlerta({ ...alerta, show: false })}
                titulo={alerta.titulo}
                mensagem={alerta.mensagem}
                textoBotao={alerta.textoBotao}
            />

        </div >
    )
}

export default TelaPropostaCliente